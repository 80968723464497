import './App.css';
import MailchimpForm from "./components/contact/MailChimpForm/MailChimpForm";
import {GHSProvider} from "./utils/ContextProvider";

function App() {



  return (
    <div className="App">
      <header className="App-header">
        <h1 className="text-white">SOVEREIGN PROPERTIES</h1>
        <div className="contact">
            <GHSProvider>
                <MailchimpForm />
            </GHSProvider>
        </div>
      </header>
    </div>
  );
}

export default App;

